<template>
  <v-container fluid fill-height>
    <v-row align="center" justify="center">
      <v-col cols="12" md="8" lg="5" xl="4">
        <v-card class="mx-4 pa-4">
          <v-form
            @submit.prevent="check_mobile()"
            ref="form1"
            v-show="!otp_verified"
            v-model="valid_form1"
          >
            <PhoneField ref="phoneField" v-model="countryPhone"></PhoneField>
            <v-alert type="info" class="d-flex align-items-center">
              Please save the below pin code for your login
            </v-alert>
            <v-text-field
              readonly
              :rules="$requiredRules"
              v-model="password"
            ></v-text-field>
            <v-btn type="submit" color="success" block>Continue</v-btn>
          </v-form>
          <v-form ref="form2" v-show="otp_verified">
            <v-text-field
              v-model="username"
              label="Username"
              :rules="$requiredRules"
            >
            </v-text-field>
            <v-row>
              <v-col cols="12" lg="6">
                <v-text-field
                  v-model="first_name"
                  label="First Name"
                  :rules="$requiredRules"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field
                  v-model="last_name"
                  label="Last Name"
                  :rules="$requiredRules"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-text-field
              v-model="address"
              :rules="$requiredRules"
              label="Address"
            ></v-text-field>
            <v-text-field
              v-model="email"
              type="email"
              :rules="$requiredRules"
              label="Email"
            ></v-text-field>
            <v-btn @click="register()" color="success" block
              >Create Account</v-btn
            >
          </v-form>
        </v-card>
        <v-snackbar
          :timeout="5000"
          v-model="snackbar"
          :color="snackbar_color"
          bottom
          left
        >
          {{ snackbar_text }}
        </v-snackbar>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PhoneField from "../components/global/PhoneField.vue";
export default {
  components: { PhoneField },
  name: "Register",
  data() {
    return {
      snackbar: false,
      snackbar_text: "",
      snackbar_color: "",
      valid_form1: true,
      form1: "",
      form2: "",
      first_name: "",
      last_name: "",
      mobile: "",
      address: undefined,
      email: undefined,
      password: Math.random().toString().slice(2, 6),
      otp: "",
      username: "",
      pass_generated: false,
      server_otp: "",
      otp_verified: false,
      reg_complete: false,
      profile_completed: false,
      countryPhone: {},
    };
  },
  methods: {
    check_mobile() {
      if (this.$refs.phoneField.validate() && this.$refs.form1.validate()) {
        this.otp_verified = true;
        this.$refs.form2.resetValidation();
      }
    },
    register() {
      if (this.$refs.form2.validate()) {
        const data = {
          username: this.username,
          first_name: this.first_name,
          last_name: this.last_name,
          country_code: this.countryPhone.country_code,
          phone: this.countryPhone.phone,
          address: this.address,
          password: this.password,
          email: this.email,
        };
        // const register_url =
        // process.env.NODE_ENV === "production"
        //   ? process.env.VUE_APP_BACKEND_BASEURL + "users/" //TODO: search possibility
        //   : "http://localhost:8000/api/users/";

        return this.$api
          .post("users/", data, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then(() =>
            this.$store.dispatch("user/login", {
              phone: this.countryPhone.phone,
              password: this.password,
              country_code: this.countryPhone.country_code,
            })
          )
          .then(() => this.$router.push("/profile"))
          .catch((err) => {
            console.log(err);
            this.snackbar = true;
            this.snackbar_text = err;
            this.snackbar_color = "red lighten-2";
          });
      }
    },
  },
};
</script>
